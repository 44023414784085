<template>
    <div class="user-view-container">
        <div class="title-container">
            <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i><span class="view-name">个人信息</span></div>
        </div>
        <div class="user-info-container">
            <div class="user-icon-section">
                <div class="user-icon-box">
                    <img class="user-icon" src="../../assets/user-icon.png" />
                </div>
            </div>
            <div class="user-info-section">
                <div class="info-item separator" @click="changeUserIcon()">
                    <div class="icon-box">
                        <div class="field-name">头像</div>
                        <div class="user-icon-box">
                            <img class="user-icon" src="../../assets/user-icon.png" />
                        </div>
                    </div>
                    <div class="next-icon">
                        <div class="arrow-icon"><i class="el-icon-arrow-right"></i></div>
                    </div>
                </div>
                <div class="info-item separator" @click="changeUserName()">
                    <div class="field-box">
                        <div class="field-name">昵称</div>
                        <span class="field-value"></span>
                    </div>
                    <div class="next-icon">
                        <div class="arrow-icon"><i class="el-icon-arrow-right"></i></div>
                    </div>
                </div>
                <div class="info-item" @click="changeUserPhone()">
                    <div class="field-box">
                        <div class="field-name">手机</div>
                        <span class="field-value">{{phoneNumber}}</span>
                    </div>
                    <div class="next-icon">
                        <div class="arrow-icon"><i class="el-icon-arrow-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="user-icon-view" v-if="iconViewVisible">
            <!-- <UserIconEditView v-on:closeView="closeUserIconView" /> -->
        </div>
        <div class="user-name-view" v-if="nameViewVisible">
            <!-- <UserNameEditView v-on:closeView="closeUserNameView" /> -->
        </div>
        <div class="user-phone-view" v-if="phoneViewVisible">
            <!-- <UserPhoneEditView v-on:closeView="closeUserPhoneView" /> -->
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import { store } from '../../utils/store'
// import UserIconEditView from './UserIconEditView.vue'
// import UserNameEditView from './UserNameEditView.vue'
// import UserPhoneEditView from './UserPhoneEditView.vue'

export default {
    name: 'UserInfoView',
    components: {
        // UserIconEditView,
        // UserNameEditView,
        // UserPhoneEditView
    },
    data() {
        return {
            iconViewVisible: false,
            nameViewVisible: false,
            phoneViewVisible: false
        }
    },
    computed: {
        phoneNumber: function() {
            return store.phoneNumber;
        }
    },
    methods: {
        closeView() {
            this.$emit("closeView");
            this.$router.go(-1);
        },
        changeUserIcon() {
            // this.iconViewVisible = true;
            this.$router.push('info/icon');
        },
        closeUserIconView() {
            this.iconViewVisible = false;
        },
        changeUserName() {
            // this.nameViewVisible = true;
            this.$router.push('info/name');
        },
        closeUserNameView() {
            this.nameViewVisible = false;
        },
        changeUserPhone() {
            // this.phoneViewVisible = true;
            this.$router.push('info/phone');
        },
        closeUserPhoneView() {
            this.phoneViewVisible = false;
        }
    }
}
</script>

<style scoped>
    .user-view-container {
        position: relative;
        height: 100%;
        overflow-y: auto;

        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 2;
    }
    .title-container {
        height: 12vmin;

        display: flex;
        align-items: stretch;
    }
    .title-container .back-icon {
        font-size: 6vmin;
        padding: 0 3vmin;

        display: flex;
        align-items: center;
    }
    .title-container .view-name {
        font-size: 4vmin;
        margin-left: 3vmin;
    }
    .title-container .back-icon:active {
        background-color: #eaeaea;
    }

    .user-info-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 12vmin;
        bottom: 0;

        font-size: 4vmin;
        text-align: left;
        overflow-y: auto;
    }

    .user-icon-section {
        background-color: #fff;
        padding: 2vmin 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .user-icon-section .user-icon-box {
        width: 96px;
        height: 96px;
        border-radius: 50%;
    }
    .user-icon-section .user-icon {
        border-radius: 50%;
    }
    .user-info-section {
        background-color: #fff;
        margin-top: 2vmin;
    }

    .info-item {
        position: relative;
        background-color: #fff;
        color: #222;
        padding: 1.5vmin 3vmin;
        text-align: left;
        font-size: 4vmin;

        display: flex;
        align-items: stretch;
    }
    .info-item:active {
        background-color: #eaeaea;
    }
    .info-item.separator {
        border-bottom: 1px solid #e0e0e0;
    }
    .info-item .field-box,
    .info-item .icon-box {
        flex-grow: 1;

        display: flex;
    }
    .info-item .icon-box {
        justify-content: space-between;
        padding-right: 2vmin;
    }
    .info-item .next-icon {
        font-size: 4vmin;
        color: #ccc;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .info-item .field-name {
        color: #222;
        width: 20vmin;
        padding: 2vmin 0;
    }
    .info-item .field-value {
        color: #888;
        padding: 2.5vmin 0;
    }
    .info-item .user-icon-box {
        width: 10vmin;
        height: 10vmin;
    }
    .info-item .user-icon-box .user-icon {
        width: 100%;
        height: 100%;
        border-radius: 3vmin;
    }

    .user-icon-view,
    .user-name-view,
    .user-phone-view {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 3;
    }
</style>