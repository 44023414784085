var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-view-container"},[_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"back-icon",on:{"click":function($event){return _vm.closeView()}}},[_c('i',{staticClass:"el-icon-back"}),_c('span',{staticClass:"view-name"},[_vm._v("个人信息")])])]),_c('div',{staticClass:"user-info-container"},[_vm._m(0),_c('div',{staticClass:"user-info-section"},[_c('div',{staticClass:"info-item separator",on:{"click":function($event){return _vm.changeUserIcon()}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"info-item separator",on:{"click":function($event){return _vm.changeUserName()}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"info-item",on:{"click":function($event){return _vm.changeUserPhone()}}},[_c('div',{staticClass:"field-box"},[_c('div',{staticClass:"field-name"},[_vm._v("手机")]),_c('span',{staticClass:"field-value"},[_vm._v(_vm._s(_vm.phoneNumber))])]),_vm._m(5)])])]),(_vm.iconViewVisible)?_c('div',{staticClass:"user-icon-view"}):_vm._e(),(_vm.nameViewVisible)?_c('div',{staticClass:"user-name-view"}):_vm._e(),(_vm.phoneViewVisible)?_c('div',{staticClass:"user-phone-view"}):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-icon-section"},[_c('div',{staticClass:"user-icon-box"},[_c('img',{staticClass:"user-icon",attrs:{"src":require("../../assets/user-icon.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box"},[_c('div',{staticClass:"field-name"},[_vm._v("头像")]),_c('div',{staticClass:"user-icon-box"},[_c('img',{staticClass:"user-icon",attrs:{"src":require("../../assets/user-icon.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"next-icon"},[_c('div',{staticClass:"arrow-icon"},[_c('i',{staticClass:"el-icon-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-box"},[_c('div',{staticClass:"field-name"},[_vm._v("昵称")]),_c('span',{staticClass:"field-value"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"next-icon"},[_c('div',{staticClass:"arrow-icon"},[_c('i',{staticClass:"el-icon-arrow-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"next-icon"},[_c('div',{staticClass:"arrow-icon"},[_c('i',{staticClass:"el-icon-arrow-right"})])])
}]

export { render, staticRenderFns }